import HTTPWrapper from '../utilities/HTTPWrapper';

class API {
  getBackgroundJobStatus = jobId => HTTPWrapper.get(`/api/background_job_statuses/${jobId}`);

  createNewBand = timetableId =>
    HTTPWrapper.get(`/staff/timetables/${timetableId}/create_new_band.json`);

  createNewTime = timetableId =>
    HTTPWrapper.get(`/staff/timetables/${timetableId}/create_new_calendar_time.json`);

  removeUnusedEvents = timetableId =>
    HTTPWrapper.get(`/staff/timetables/${timetableId}/remove_unused_events.json`);

  createNewDate = (timetableId, params) =>
    HTTPWrapper.get(`/staff/timetables/${timetableId}/create_new_calendar_date.json`, params);

  createNewAllDayEvent = (timetableId, params) =>
    HTTPWrapper.post(`/staff/timetables/${timetableId}/all_day_events.json`, params);

  deleteBand = id => HTTPWrapper.delete(`/staff/bands/${id}.json`);

  deleteTime = id => HTTPWrapper.delete(`/staff/calendar_times/${id}.json`);

  deleteDate = id => HTTPWrapper.delete(`/staff/calendar_dates/${id}.json`);

  deleteCoverDelegation = id => HTTPWrapper.delete(`/staff/cover_delegations/${id}.json`);

  deleteAllFutureDates = (id, params) => HTTPWrapper.post(`/staff/timetables/${id}/delete_all_future_dates.json`, params);

  deleteDateAllDay = id => HTTPWrapper.delete(`/staff/all_day_events/${id}.json`);

  changeToLessons = id => HTTPWrapper.post(`/staff/all_day_events/${id}/change_to_lessons.json`);

  assignKidToBand = (kidId, params) =>
    HTTPWrapper.post(`/staff/kids/${kidId}/assign_to_band.json`, params);

  assignBlockToSlot = params => HTTPWrapper.post('/staff/slots/create_blocked_slot.json', params);

  updateBlock = params => HTTPWrapper.post('/staff/slots/update_blocked_slot.json', params);

  deleteSlot = id => HTTPWrapper.delete(`/staff/slots/${id}.json`);

  swapKids = params => HTTPWrapper.post('/staff/kids/swap.json', params);

  addContactNoteToKid = (kidId, params) =>
    HTTPWrapper.post(`/staff/kids/${kidId}/contact_notes.json`, params);

  getAllDayEventCategories = () => HTTPWrapper.get('/api/all_day_event_categories.json');

  getContactNotesForKid = kidId => HTTPWrapper.get(`/staff/kids/${kidId}/contact_notes.json`);

  addContactNoteToSchool = (urn, params) =>
    HTTPWrapper.post(`/staff/schools/${urn}/contact_notes.json`, params);

  getContactNotesForSchool = urn => HTTPWrapper.get(`/staff/schools/${urn}/contact_notes.json`);

  getContactNotesForGrownUp = grownUpId =>
    HTTPWrapper.get(`/staff/grown_ups/${grownUpId}/contact_notes.json`);

  getContactNotesForJob = jobId => HTTPWrapper.get(`/staff/jobs/${jobId}/contact_notes.json`);

  getDataForBandLeader = () => HTTPWrapper.get('/pwa/data-for-band-leader');

  getDataForDashboard = () => HTTPWrapper.get('/api/jobs/overview');

  getTimetableData = (id, params) =>
    HTTPWrapper.get(`/staff/timetables/${id}/data/all.json`, params);

  getTimetableRates = () => HTTPWrapper.get('/staff/price_monthly_items/timetable_rates.json');

  getKidRates = () => HTTPWrapper.get('/staff/price_monthly_items/kid_rates.json');

  getTimetableRegisterData = (id, params) =>
    HTTPWrapper.get(`/staff/timetables/${id}/data/register.json`, params);

  getTimetableBandsData = id => HTTPWrapper.get(`/staff/timetables/${id}/data/bands.json`);

  getTimetableCalendarData = (id, params) =>
    HTTPWrapper.get(`/staff/timetables/${id}/data/calendar.json`, params);

  getTimetableCalendarAndRegisterData = (id, params) =>
    HTTPWrapper.get(`/staff/timetables/${id}/data/calendar_and_register.json`, params);

  getTimetableCalendarAndBandsData = (id, params) =>
    HTTPWrapper.get(`/staff/timetables/${id}/data/calendar_and_bands.json`, params);

  getKidInfo = id => HTTPWrapper.get(`/staff/kids/${id}.json`);

  getTimetableInfo = id => HTTPWrapper.get(`/staff/timetables/${id}.json`);

  getYearlyRegisters = (id, params) =>
    HTTPWrapper.get(`/staff/timetables/${id}/data/yearly_registers.json`, params);

  getTimetablesForSchool = id => HTTPWrapper.get(`/staff/schools/${id}/timetables.json`);

  getTimetablesForJob = id => HTTPWrapper.get(`/staff/jobs/${id}/timetables.json`);

  getSchoolsInHouseData = schoolURN =>
    HTTPWrapper.get(`/staff/schools_in_house_data/${schoolURN}.json`);

  getSchoolSalesLeads = schoolURN =>
    HTTPWrapper.get(`/staff/schools/${schoolURN}/sales_leads.json`);

  getSalesTrackerSchools = id => HTTPWrapper.get(`/staff/sales_trackers/${id}/schools.json`);

  getSalesTrackerJobs = id => HTTPWrapper.get(`/staff/sales_trackers/${id}/jobs.json`);

  getDistancesForSalesTrackerGap = (id, jobId, params) =>
    HTTPWrapper.get(`/staff/sales_trackers/${id}/jobs/${jobId}/distances_for_gap.json`, params);

  getDistancesFromSalesTrackerCentrePoint = (id, jobId) =>
    HTTPWrapper.get(`/staff/sales_trackers/${id}/jobs/${jobId}/distances_from_centre_point.json`);

  getNearestSchoolsForJobGap = (jobId, params) =>
    HTTPWrapper.get(`/staff/jobs/${jobId}/find_nearest_schools.json`, params);

  getJobInfo = id => HTTPWrapper.get(`/staff/jobs/${id}.json`);

  getSchoolInfo = id => HTTPWrapper.get(`/staff/schools/${id}.json`);

  getSchoolTransferInfo = id => HTTPWrapper.get(`/staff/schools/${id}/transfer_data_summary.json`);

  getSchoolNumbers = urn => HTTPWrapper.get(`/api/schools/${urn}/numbers.json`);

  getSuitableJobSlotsForSchool = (urn, params) => HTTPWrapper.get(`/staff/schools/${urn}/find_suitable_job_slots.json`, params);

  getNearestJobsWithDistancesForSchool = (urn, params) => HTTPWrapper.get(`/staff/schools/${urn}/find_nearest_jobs_with_distances.json`, params);

  getSchoolSatsPreferences = id => HTTPWrapper.get(`/staff/schools_in_house_data/${id}/sats_preferences`);

  getImpactData = urn => HTTPWrapper.get(`/staff/impact_report_data/${urn}.json`)

  getGrantBodyInfo = id => HTTPWrapper.get(`/staff/grant_bodies/${id}.json`);

  getTasksForSchool = id => HTTPWrapper.get(`/staff/schools/${id}/tasks/uncompleted_tasks.json`);

  getTasksForKid = id => HTTPWrapper.get(`/staff/kids/${id}/tasks/uncompleted_tasks.json`);

  getTasksForJob = id => HTTPWrapper.get(`/staff/jobs/${id}/tasks/uncompleted_tasks.json`);

  deleteTaskForSchool = (id, taskID) => HTTPWrapper.delete(`/staff/schools/${id}/tasks/${taskID}`);

  deleteTaskForJob = (id, taskID) => HTTPWrapper.delete(`/staff/jobs/${id}/tasks/${taskID}`);

  deleteTaskForKid = (id, taskID) => HTTPWrapper.delete(`/staff/kids/${id}/tasks/${taskID}`);

  createTaskForSchool = (SchoolId, taskParams) =>
    HTTPWrapper.post(`/staff/schools/${SchoolId}/tasks`, taskParams);

  createTaskForKid = (kidId, taskParams) =>
    HTTPWrapper.post(`/staff/kids/${kidId}/tasks`, taskParams);

  bulkAssignTasksToKids = (timeTableId, taskParams) =>
    HTTPWrapper.post(`/staff/timetables/${timeTableId}/bulk_assign_tasks_to_kids`, taskParams);

  creditKids = (id, params) => HTTPWrapper.post(`/staff/timetables/${id}/credit_kids.json`, params);

  emailConcertVideos = id => HTTPWrapper.post(`/staff/timetables/${id}/email_concert_videos.json`);

  createTaskForJob = (jobId, taskParams) =>
    HTTPWrapper.post(`/staff/jobs/${jobId}/tasks`, taskParams);

  updateTaskForSchool = (SchoolId, taskParams) =>
    HTTPWrapper.patch(`/staff/schools/${SchoolId}/tasks/${taskParams.id}`, taskParams);

  updateTaskForJob = (jobId, taskParams) =>
    HTTPWrapper.patch(`/staff/jobs/${jobId}/tasks/${taskParams.id}`, taskParams);

  updateTaskForKid = (kidId, taskParams) =>
    HTTPWrapper.patch(`/staff/kids/${kidId}/tasks/${taskParams.id}`, taskParams);

  completeTaskForSchool = (id, taskID) =>
    HTTPWrapper.post(`/staff/schools/${id}/tasks/${taskID}/complete`);

  completeTaskForJob = (id, taskID) =>
    HTTPWrapper.post(`/staff/jobs/${id}/tasks/${taskID}/complete`);

  completeTaskForKid = (id, taskID) =>
    HTTPWrapper.post(`/staff/kids/${id}/tasks/${taskID}/complete`);

  getUserInfo = id => HTTPWrapper.get(`/staff/users/${id}.json`);

  getNonTeachingStaff = () => HTTPWrapper.get('/staff/users/staff_non_teaching.json');

  getPossibleNextActions = () => HTTPWrapper.get('/staff/kids/possible_next_actions.json');

  getGrownUpInfo = id => HTTPWrapper.get(`/staff/grown_ups/${id}.json`);

  getGrownUpTransactions = id => HTTPWrapper.get(`/staff/grown_ups/${id}/transactions.json`);

  findGrownUp = query => HTTPWrapper.get(`/staff/grown_ups/find?q=${query}`);

  globalSearch = query => HTTPWrapper.get(`/api/search/search?q=${query}`);

  findJob = query => HTTPWrapper.get(`/staff/jobs/find?q=${query}`);

  findUser = query => HTTPWrapper.get(`/staff/users/find?q=${query}`);

  findSchool = query => HTTPWrapper.get(`/staff/schools/find?q=${query}`);

  findGrantBody = query => HTTPWrapper.get(`/staff/grant_bodies/find?q=${query}`);

  updateGrownUp = (id, params) => HTTPWrapper.patch(`/staff/grown_ups/${id}.json`, params);

  createGrownUp = params => HTTPWrapper.post('/staff/grown_ups.json', params);

  bulkAddSchoolsToTracker = (id, params) =>
    HTTPWrapper.post(`/staff/sales_trackers/${id}/bulk_assign_schools.json`, params);

  deleteGrownUp = id => HTTPWrapper.delete(`/staff/grown_ups/${id}.json`);

  deleteContactNote = id => HTTPWrapper.delete(`/staff/contact_notes/${id}.json`);

  updateContactNote = (id, params) =>
    HTTPWrapper.patch(`/staff/contact_notes/${id}.json`, params);

  sendNpsRequest = id => HTTPWrapper.post(`/staff/grown_ups/${id}/send_nps_request.json`);

  sendVideoPermissionsConfirmation = (id, params) => HTTPWrapper.post(`/staff/grown_ups/${id}/send_video_permissions_confirmation.json`, params);

  deDuplicateGrownUps = id => HTTPWrapper.post(`/staff/grown_ups/${id}/deduplicate.json`);

  resetBankDetails = id => HTTPWrapper.patch(`/staff/grown_ups/${id}/reset_bank_details.json`);

  updateKidRestricted = (id, params) =>
    HTTPWrapper.patch(`/staff/kids/${id}/restricted_update.json`, params);

  updateKid = (id, params) => HTTPWrapper.patch(`/staff/kids/${id}.json`, params);

  updateTimetable = (id, params) => HTTPWrapper.patch(`/staff/timetables/${id}.json`, params);

  updateMyTimetable = (id, params) =>
    HTTPWrapper.post(`/staff/timetables/${id}/update_my_timetable.json`, params);

  updateSchoolsInHouseData = (id, params) =>
    HTTPWrapper.patch(`/staff/schools_in_house_data/${id}.json`, params);

  updateJob = (id, params) => HTTPWrapper.patch(`/staff/jobs/${id}.json`, params);

  updateBandField = (id, params) => HTTPWrapper.patch(`/staff/bands/${id}.json`, params);

  updateAttendanceRecord = (id, params) =>
    HTTPWrapper.patch(`/staff/attendance_records/${id}.json`, params);

  updateBandAgeRange = (id, params) => HTTPWrapper.patch(`/staff/bands/${id}.json`, params);

  updateTime = (id, params) => HTTPWrapper.patch(`/staff/calendar_times/${id}.json`, params);

  updateDate = (id, params) => HTTPWrapper.patch(`/staff/calendar_dates/${id}.json`, params);

  changeToAllDayEvent = (id, params) =>
    HTTPWrapper.post(`/staff/calendar_dates/${id}/change_to_all_day_event.json`, params);

  updateDateAllDay = (id, params) => HTTPWrapper.patch(`/staff/all_day_events/${id}.json`, params);

  updateAllDayEvent = (id, params) => HTTPWrapper.patch(`/staff/all_day_events/${id}.json`, params);

  updateRehearsal = (id, params) => HTTPWrapper.patch(`/staff/rehearsals/${id}.json`, params);

  findTimetable = query => HTTPWrapper.get(`/staff/timetables/find?q=${query}`);

  createKid = params => HTTPWrapper.post('/staff/kids.json', params);

  createTimetable = params => HTTPWrapper.post('/staff/timetables.json', params);

  updateKid = (id, params) => HTTPWrapper.patch(`/staff/kids/${id}.json`, params);

  refreshKidPaymentInfo = (id, params) => HTTPWrapper.post(`/staff/kids/${id}/refresh_payment_info.json`, params);

  getKidsForGrownUp = (id, limit) => HTTPWrapper.get(`/staff/grown_ups/${id}/kids.json?limit=${limit}`);

  getPredictedChargeForGrownUp = id => HTTPWrapper.get(`/staff/grown_ups/${id}/predicted_charge.json`)

  getDirectDebitsForGrownUp = id => HTTPWrapper.get(`/staff/grown_ups/${id}/direct_debits.json`);

  getBillingAccountForGrownUp = id => HTTPWrapper.get(`/staff/grown_ups/${id}/billing_account.json`);

  getDirectDebitById = params => HTTPWrapper.get('/staff/direct_debits/find.json', params);

  getAttendanceRecords = id => HTTPWrapper.get(`/staff/registers/${id}/attendance_records.json`);

  getNpsRecords = id => HTTPWrapper.get(`/staff/grown_ups/${id}/nps`);

  createNpsRecord = (id, params) => HTTPWrapper.post(`/staff/grown_ups/${id}/nps`, params);

  getSongs = (page, params) => HTTPWrapper.get(`/staff/songs.json?page=${page}`, params);

  getSongsWithNoPagination = () => HTTPWrapper.get('/staff/songs.json', { show_all: 'Y' });

  getBenefitSurveys = (kidId, page) =>
    HTTPWrapper.get(`/staff/kids/${kidId}/benefit_survey.json?page=${page}`);

  getSong = id => HTTPWrapper.get(`/staff/songs/${id}.json`);

  createSong = params => HTTPWrapper.post('/staff/songs.json', params);

  transferSchool = params => HTTPWrapper.post('/staff/schools/transfer.json', params);

  sendEnrolWelcomeCommunications = kidId =>
    HTTPWrapper.post(`/staff/kids/${kidId}/send_enrol_welcome_communications.json`, {});

  sendWaitingListWelcomeEmail = kidId =>
    HTTPWrapper.post(`/staff/kids/${kidId}/send_waiting_list_welcome_email.json`, {});

  sendDigestEmail = kidId =>
    HTTPWrapper.post(`/staff/kids/${kidId}/send_digest_email.json`, {});

  sendPasswordResetInstructions = userId =>
    HTTPWrapper.post(`/staff/users/${userId}/send_password_reset_instructions.json`);

  cancelKid = id => HTTPWrapper.post(`/staff/kids/${id}/cancel.json`, {});

  removeKidFromWaitingList = id =>
    HTTPWrapper.post(`/staff/kids/${id}/remove_from_waiting_list.json`, {});

  setStatusAsReserved = id => HTTPWrapper.post(`/staff/kids/${id}/set_reserved.json`, {});

  setStatusAsEnrolled = id => HTTPWrapper.post(`/staff/kids/${id}/set_enrolled.json`, {});

  moveToWaitingList = id => HTTPWrapper.post(`/staff/kids/${id}/move_to_waiting_list.json`, {});

  getCurrentUserData = () => HTTPWrapper.get('/staff/users/current_user_data.json');

  deleteKid = id => HTTPWrapper.delete(`/staff/kids/${id}.json`, {});

  getTodayDashboardData = () => HTTPWrapper.get('/staff/dashboards/today_data.json');

  getTotalsDashboardData = () => HTTPWrapper.get('/staff/dashboards/totals_data.json');

  rotateAllBandsFromDate = (id, params) =>
    HTTPWrapper.post(`/staff/timetables/${id}/rotate_all_bands_from_date.json`, params);

  bulkAddRehearsals = (id, params) =>
    HTTPWrapper.post(`/staff/timetables/${id}/bulk_add_rehearsals.json`, params);

  validateBankDetails = params =>
    HTTPWrapper.post('/api/smart_debits/validate.json', params);

  getRahLessonSeries = () => HTTPWrapper.get('/api/rah_lesson_series.json');

  getTrackDataBySongId = id => HTTPWrapper.get(`/api/songs/${id}/full_track_data`);

  createRahVideoLesson = params => HTTPWrapper.post('/api/rah_video_lessons', params);

  editRahVideoLesson = (id, params) => HTTPWrapper.patch(`/api/rah_video_lessons/${id}.json`, params);

  createPayment = params => HTTPWrapper.post('/staff/payments.json', params);

  getPaymentTypes = () => HTTPWrapper.get('/staff/payments/payment_types.json');

  getPaymentDescriptions = () => HTTPWrapper.get('/staff/payments/descriptions.json');

  bulkApplySingleCharges = params => HTTPWrapper.post('/staff/single_charges/bulk_add_charges.json', params);

  getPriceSingleItems = () => HTTPWrapper.get('/staff/price_single_items.json');

  getCreditDescriptions = () => HTTPWrapper.get('/staff/credits/descriptions.json');

  createCredit = params => HTTPWrapper.post('/staff/credits.json', params);

  createRefund = params => HTTPWrapper.post('/staff/refunds.json', params);

  getChargeDescriptions = () => HTTPWrapper.get('/staff/single_charges/descriptions.json');

  createConcertVideo = params => HTTPWrapper.post('/staff/concert_videos.json', params);

  editConcertVideo = (id, params) => HTTPWrapper.patch(`/staff/concert_videos/${id}.json`, params);

  getBlaDebugDump = id => HTTPWrapper.get(`/staff/bla_debug_dumps/${id}.json`);

  sendTimelineEntriesBulkMessage = params => HTTPWrapper.post('/staff/timeline_entries_bulk_message', params);

  getLATermDates = year => HTTPWrapper.get(`/staff/la_term_dates.json?school_year_beginning_sept=${year}`);

  updateLATermDates = (id, params) => HTTPWrapper.patch(`/staff/la_term_dates/${id}`, params);

  generateLATermDates = year => HTTPWrapper.post('/staff/la_term_dates/generate_data', { school_year_beginning_sept: year });

  sendComms = (timetableID, params) => HTTPWrapper.post(`/staff/send_comms/timetable/${timetableID}/deliver.json`, params);

  getTimetableAssignedKids = id => HTTPWrapper.get(`/staff/send_comms/kids?timetable_id=${id}`);

  resetRegister = params => HTTPWrapper.post('/staff/registers/reset.json', params);

  getSchoolContacts = urn => HTTPWrapper.get(`/staff/schools/${urn}/school_contacts.json`);

  fetchAllTemplates = () => HTTPWrapper.get('/staff/standard_comms_templates.json');

  fetchKidDatum = id => HTTPWrapper.get(`/staff/school_submitted_kid_data/${id}.json`)

  getRecognisedSchoolWebDomains = urn => HTTPWrapper.get(`/staff/schools/${urn}/recognised_school_web_domains.json`);

  createRecognisedSchoolWebDomain = (urn, params) => HTTPWrapper.post(`/staff/schools/${urn}/recognised_school_web_domains.json`, params);

  deleteRecognisedSchoolWebDomain = id => HTTPWrapper.delete(`/staff/recognised_school_web_domains/${id}.json`);

  kidTrinityStages = kidId => HTTPWrapper.get(`/staff/kids/${kidId}/trinity_stages.json`);

  getJobImpactData = jobId => HTTPWrapper.get(`/api/jobs/${jobId}/impact_dashboard_data`);

  getBandLeaders = () => HTTPWrapper.get('/api/band_leaders');

  getBandLeaderSupports = () => HTTPWrapper.get('/api/band_leader_supports');

  getBandLeaderSupportBandLeaders = id => HTTPWrapper.get(`/api/band_leader_supports/${id}/band_leaders`);

  createBandMediaDiscrepancyReport = params => HTTPWrapper.post('/api/band_media_discrepancy_reports', params);

  getFeatureFlags = () => HTTPWrapper.get('/api/feature_flags');

  getStageSkills = (stage, params) => HTTPWrapper.get(`/api/v1/stages/${stage}/skills`, params);

  getKidLearntSkills = (kidId, params) => HTTPWrapper.get(`/api/v1/kids/${kidId}/learnt_skills`, params);

  getKidCurrentStageProgressReport = kidId => HTTPWrapper.get(`/api/v1/kids/${kidId}/current_stage_progress_report`);

  getLearntSkillSkillLogs = (learntSkillId, params) => HTTPWrapper.get(`/api/v1/learnt_skills/${learntSkillId}/skill_logs`, params);
}

export default new API();
